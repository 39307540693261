import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import "./App.css";

const App = () => (
  <Container className="p-3">
    <Container className="p-5 mb-4 bg-light rounded-3">
      <h1 className="header">Welcome To ChatFuse</h1>

      <p className="lead">
        Due to restrictions on Guest accounts in Microsoft Teams, Guests are
        unable to search for other users to message them.
        <br />
        This app works around this by constructing a Teams deep link with the
        users you enter below which skips the search operation.
      </p>

      <p className="lead">
        If you wish to message another Guest user within the tenant, you'll need
        to enter the tenant identifier as well. This takes the form of{" "}
        <kbd>companynamehere.onmicrosoft.com</kbd>
      </p>

      <RenderForm />
    </Container>
  </Container>
);

const initialList = [
  {
    id: 0,
    email: "",
    isGuest: false,
  },
];

let nextID = initialList.length;

function RenderForm() {
  const [tenantID, setTenantID] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("tenantID");
    return saved || "";
  });
  const [userList, setUserList] = useState(initialList);

  function updateTenantID(newTenantID) {
    localStorage.setItem("tenantID", newTenantID);
    setTenantID(newTenantID);
  }

  function updateUserEmail(id, newUserEmail) {
    const nextUserList = userList.map((user) => {
      if (user.id !== id) {
        // No change
        return user;
      } else {
        // Return a new user with updated value
        return {
          ...user,
          email: newUserEmail,
        };
      }
    });
    // Re-render with the new array
    setUserList(nextUserList);
  }

  function updateUserIsGuest(id, newIsGuest) {
    const nextUserList = userList.map((user) => {
      if (user.id !== id) {
        // No change
        return user;
      } else {
        // Return a new user with updated value
        return {
          ...user,
          isGuest: newIsGuest,
        };
      }
    });
    // Re-render with the new array
    setUserList(nextUserList);
  }

  return (
    <>
      <Form.Label htmlFor="tenant-id">Tenant Identifier</Form.Label>

      <InputGroup className="mb-4">
        <Form.Control
          type="text"
          placeholder="domain (only needed if adding guest accounts)"
          id="tenant-id"
          aria-describedby="tenantHelpBlock"
          value={tenantID}
          onChange={(e) => updateTenantID(e.target.value)}
        />
        <InputGroup.Text id="tenantHelpBlock">.onmicrosoft.com</InputGroup.Text>
      </InputGroup>

      {userList.map((user) => (
        <>
          <InputGroup className="mb-3">
            {userList.length !== 1 && (
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setUserList(userList.filter((u) => u.id !== user.id));
                }}
              >
                <span role="img" aria-label="delete button">
                  ❌
                </span>
              </Button>
            )}

            <Form.Control
              aria-label="Text input with checkbox"
              type="email"
              placeholder="user@example.com"
              aria-describedby="userHelpBlock"
              value={user.userEmail}
              onChange={(e) => updateUserEmail(user.id, e.target.value)}
            />
            <InputGroup.Text id="userHelpBlock">Guest User?</InputGroup.Text>
            <InputGroup.Checkbox
              type="checkbox"
              label="Guest user checkbox"
              checked={user.isGuest}
              onChange={(e) => updateUserIsGuest(user.id, e.target.checked)}
            />
          </InputGroup>
        </>
      ))}

      <ButtonGroup className="mb-3" aria-label="Basic example">
        <Button
          type="button"
          className="btn btn-secondary"
          onClick={() =>
            setUserList(
              // Replace the state
              [
                // with a new array
                ...userList, // that contains all the old items
                { id: nextID++, email: "" }, // and one new item at the end
              ]
            )
          }
        >
          Add User
        </Button>
      </ButtonGroup>
      <div className="d-grid gap-2">
        <Button
          variant="primary"
          size="lg"
          onClick={() => launchTeams(userList, tenantID)}
        >
          Launch Teams
        </Button>
      </div>
    </>
  );
}

function launchTeams(userList, tenantID) {
  let url = "https://teams.microsoft.com/l/chat/0/0?users=";
  let tenantDomain = tenantID + ".onmicrosoft.com";

  let processedEmails = userList.map((user) => {
    if (user.isGuest) {
      return user.email.replace("@", "_") + "%23EXT%23@" + tenantDomain;
    } else {
      return user.email;
    }
  });

  for (let i = 0; i < processedEmails.length; i++) {
    if (i > 0) {
      url = url + ",";
    }

    url = url + processedEmails[i];
  }

  console.log(url);
  window.open(url, "_blank", "noreferrer");
}

export default App;
